import React from 'react';

const Featured = ((data:any): JSX.Element=>{
    const post = data.data
    return(
        <div className='featured'>
            <div className="wrap-featured">
                <div className='featured-picture'>
                    <div className="bg-img" style={{backgroundImage: "url("+( post?.fluid ? post?.fluid.gatsbyImageData.images.fallback.src : "") +")"}}></div>
                    <div className="bg-over"></div>
                </div>
                <div className="content">
                    <div className="content-wrap">
                        <div className="cat">
                            <p>{post.category}</p>
                        </div>
                        <div className="title">
                            <a href={post.slug}>{post.title}</a>
                        </div>
                        <div className="date-autor">
                            <p>{post.date}. {post.author}.</p>
                        </div>
                        <div className="resume">
                            <div className="resume-text">
                                <p>{post.description}</p>
                            </div>
                            <div className="link">
                                <a href={post.slug}>Ver más</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})
export default Featured;