import React from 'react';

import "../../theme/category.scss"

interface PropsRecents {
  posts: any;
  title: string;
  link: boolean;
}
const RecentPosts = ({posts, title, link = false}:PropsRecents): JSX.Element=>{
    const recientes = posts;

    // console.log(posts)

    return(
        <>
            <div className='recientes'>
              <div className="wrap-recientes">
                <div className="header-recientes">
                  <h2>{title}</h2>
                  {
                    link &&
                    <div className='action'>
                      <div className="link">
                        <a href="/todos">Ver todo</a>
                      </div>
                    </div>
                  }
                </div>
                <div className="items">
                  {
                    recientes && recientes.map((node:any)=>{

                      let highlightImg: any = undefined;

                      try {
                          highlightImg = node.fluid.gatsbyImageData.images.fallback.src;
                      } catch (error) {
                          try {
                              highlightImg = node.fluid.gatsbyImageData.images.sources[0].srcSet;
                          } catch (error2) {
                              highlightImg = "https://vectoranalisis.mx/static/images/vector/analisis.png";
                          }
                      }

                      return (
                        <div className="item">
                          <div className="picture">
                            <div className="bg" style={{backgroundImage: 'url("'+highlightImg+'")'}}></div>
                          </div>
                          <div className="content">
                            <div className="title">
                              <h2>{node.title}</h2>
                            </div>
                            <div className="date-autor">
                              <p>{node.date}. {node.author}.</p>
                            </div>
                            <div className="link">
                              <a href={node.slug}>Ver más</a>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
        </>
    )
}
export default RecentPosts;
