import * as React from 'react';
import { useState } from 'react';

import FeaturedFlash from '../../components/layout/FeaturedFlash';
import FlashCards from '../../components/FlashCards';
import {
  useFlashInternacionalPosts,
  useOtrosArticulosPosts
} from '../../operations/queries';
import getCategoryColor from '../../utils/getCategoryColor';
import Featured from '../../components/layout/Featured';
import { useDiarioInternacionalPosts } from '../../operations/queries/diarioInternacionalPosts';
import useIsClient from '../../utils/isClient';
import HeaderLayout from '../../components/layout/HeaderLayout';

import "../../theme/category.scss"
import SearchIcon from '@material-ui/icons/Search';
import CustomFooter from '../../components/CustomFooter';
import GdprCookies from '../../components/GdprCookies';
import RecentPosts from '../../components/layout/RecentPosts';

import ModalSuscribe from '../../components/layout/ModalSuscribre';



export default function Internacional(): JSX.Element {
  const flashInternacionalPosts = useFlashInternacionalPosts();
  const diarioInternacionalPosts = useDiarioInternacionalPosts();
  const otherPosts = useOtrosArticulosPosts();

  const color = getCategoryColor('internacional');

  const diario = diarioInternacionalPosts[0];
  const flash = flashInternacionalPosts[0];

  // console.log(otherPosts)
  //functions modal suscribe
  const [open, setOpen] = useState(false);
  function openSuscribe(){
    setOpen(true);
  }
  function closeSuscribe(){
    setOpen(false);
  }

  const isClient = useIsClient();
  if(isClient){
    return (
      <>
        <HeaderLayout mb={true} openModal={openSuscribe}/>

        <div className='container'>

          <div className='header--section'>
            <h2 className='title--section'>Diario Internacional</h2>
            <div className='search--section'>
              <form action="/buscar">
                <input type="text" name='q' />
                <SearchIcon className='icon--search'/>  
              </form>
            </div>
          </div>

          <Featured data={diario}/>

          <FeaturedFlash post={flash}/>

          <FlashCards
            header="Flash Internacional"
            posts={flashInternacionalPosts}
          />
          <RecentPosts posts={otherPosts} title="Otros artículos"/>

          {/* <OtherPosts posts={otherPosts}/> */}

        </div>



        {/* <CategoryLayout title="Internacional" newHeader={true}>
          <Featured
            header="Diario Internacional"
            post={diarioInternacionalPosts[0]}
            vertical
          />
          <FeaturedFlash
            header="Flash Destacado"
            post={flashInternacionalPosts[0]}
            barColor={color}
          />
          <FlashCards
            header="Flash Internacional"
            posts={flashInternacionalPosts}
          />
          <OtherPosts header="Otros Artículos" posts={otherPosts} />
        </CategoryLayout> */}
        <CustomFooter openModal={openSuscribe}/>
        <GdprCookies />
        {
          open && <ModalSuscribe closeModal={closeSuscribe}/>
        }
      </>
    );
  }else{
    return(<></>)
  }
}
