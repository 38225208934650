import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ContentfulDiarioInternacional } from '../../../types/graphql-types';
import options from '../../utils/getRichTextOptions';
import parseDate from '../../utils/parseDate';
import { PostPreview as PostsPreviewProps } from '../../models/PostPreview';
import { renderRichText } from "gatsby-source-contentful/rich-text"

export const useDiarioInternacionalPosts = (): PostsPreviewProps[] => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulDiarioInternacional(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        totalCount
        edges {
          node {
            ...diarioInternacional
          }
        }
      }
    }
  `);

  const diarioInternacionalPosts = data.allContentfulDiarioInternacional.edges.map(
    (edge: { node: ContentfulDiarioInternacional }) => {
      const {
        id,
        titulo,
        resumen,
        slug,
        createdAt,
        autor,
        heroImage,
      } = edge.node;
      const categorySlug = 'diario-internacional';

      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Diario Internacional',
        slug: `/internacional/${slug}`,
        date: parseDate(createdAt),
        categorySlug,
        author: autor?.nombre,
        fluid: heroImage,
      };
    }
  );

  return diarioInternacionalPosts;
};
